/* Home.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
  }
  .image{
    width: 100vw; 
    height:600px;/* Full viewport width */
  overflow: hidden; /* Hide any overflow caused by the image */
  position: relative;
   /* Relative positioning for the image */
  }
  .home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #f4f4f9, #e0e3ea);
    flex-grow: 1; /* Allow the main container to grow */
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }
  
  .nav {
    width: 100%;
    position: fixed; /* Fixes the navbar on top */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensures navbar stays on top */
  }
  
  .main-content {
  

    text-align: center;
  }

  .productlist{
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line if there isn't enough space */
    gap: 1rem; /* Optional: Adds space between cards */
    justify-content: space-between; /* Optional: Distributes space between cards */
    padding: 1rem; /* Optional: Adds padding around the container */

  }
  
  .main-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #2b2b2b;
    position: relative;
    z-index: 1;
  }
  
  .main-title::after {
    content: '';
    width: 150px;
    height: 5px;
    background-color: #ff7b54;
    display: block;
    margin: 20px auto 0;
    border-radius: 5px;
  }
  
  .products {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
  }
  
  .items-container {
    width: 280px;
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease;
    cursor: pointer;
    transform: perspective(1000px) rotateY(0deg);
  }
  
  .items-container:hover {
    transform: perspective(1000px) rotateY(5deg);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .items-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 5px solid #ff7b54;
  }
  
  .items-title {
    font-size: 1.5rem;
    margin-top: 15px;
    color: #333;
    font-weight: 600;
  }
  
  .items-description {
    font-size: 1rem;
    color: #666;
    margin: 10px 15px 20px;
    text-align: left;
    line-height: 1.6;
  }
  
  .spacing-section {
    height: 40px;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .main-content {
      padding: 10px;
    }
  
    .main-title {
      font-size: 2rem;
    }
    .items-container {
      width: 100%;
    }














  }
  
  
  